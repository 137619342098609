<template>
  <div>
    010 - 
    <input
      v-model='phoneNumberMiddleFourDigits'
      maxlength='4'
      class='border py-1 px-2 rounded w-20 text-center'
      @input='allowOnlyNumberMiddleFour($event)'> - 
    <input
      v-model='phoneNumberLastFourDigits'
      maxlength='4'
      class='border py-1 px-2 rounded w-20 text-center'
      @input='allowOnlyNumberLastFour($event)'>
  </div>
</template>

<script>
import { isPlainObject } from 'lodash'

export default {
  name: 'EditFormFieldKoreanPhoneNumber',
  props: [
    'metaData',
    'value',
  ],
  data () {
    return {
      phoneNumberMiddleFourDigits: '',
      phoneNumberLastFourDigits: '',
    }
  },
  watch: {
    phoneNumberValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:phoneNumberValue', newVal)
        }
      }, 
      immediate: true,
      deep: true,
    },
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
         if (isPlainObject(newVal)) {
           let splitUp = newVal.phoneNumber ? newVal.phoneNumber.split('-'): []
           this.phoneNumberMiddleFourDigits = splitUp[1] ? splitUp[1] : ''
           this.phoneNumberLastFourDigits = splitUp[2] ? splitUp[2] : ''
         } else {
          let splitUp = newVal.split('-')
          this.phoneNumberMiddleFourDigits = splitUp[1]
          this.phoneNumberLastFourDigits = splitUp[2]
         }
        }
      }, 
      immediate: true,
    },
  },
  computed: {
    isValidPhoneNumber () {
      return this.phoneNumberMiddleFourDigits.length >= 3 && this.phoneNumberLastFourDigits.length >= 3 
    },
    phoneNumberValue () {
      let phoneNumber = `010-${this.phoneNumberMiddleFourDigits}-${this.phoneNumberLastFourDigits}`
      return {
        phoneNumber: phoneNumber,
        isValidPhoneNumber: this.isValidPhoneNumber
      }
    },
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  },
  methods: {
    allowOnlyNumberMiddleFour (event) {
      this.phoneNumberMiddleFourDigits = event.target.value.replace(/[^0-9]/g, '')
    },
    allowOnlyNumberLastFour (event) {
      this.phoneNumberLastFourDigits = event.target.value.replace(/[^0-9]/g, '')
    },
  },
}
</script>
